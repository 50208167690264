import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FinishOrder from '../components/FinishOrder.jsx'
import {
  Typography,
  List,
  ListItem,
  Divider,
  Container,
  IconButton,
  Button,
  Collapse,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
const Compra = () => {
  const [finishOrder, setFinishOrder] = React.useState(false);
  const [efectivo, setEfectivo] = useState("");
  const [isCartOpen, setIsCartOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [precioTotal, setPrecioTotal] = React.useState(
    location.state?.precioTotal || 0
  );

  const [orderItems, setOrderItems] = useState(
    JSON.parse(localStorage.getItem("orderItems")) || []
  );

  const handleClick = () => {
    setFinishOrder(true);
  };
  const handleClose = () => {
    setFinishOrder(false);
  };

  const handleInputChange = (event) => {
    setEfectivo(parseFloat(event.target.value));
  };

  const priceCalculator = (item) => {
    
      let precioTotal = item.price;
      // console.log("base " + precioTotal);
      // console.log(item);
      item.modifiers.forEach((modifier) => {
        if (modifier.quantity > modifier.initialQuantity) {
          precioTotal +=
            modifier.price * (modifier.quantity - modifier.initialQuantity);
        }
      });
      // console.log('mods ' + precioTotal);
      item.extras.forEach((extras) => {
        if (extras.quantity > extras.initialQuantity) {
          precioTotal +=
            extras.price * (extras.quantity - extras.initialQuantity);
        }
      });
      item.adicionales.forEach((adicionales) => {
        if (adicionales.quantity > adicionales.initialQuantity) {
          precioTotal +=
            adicionales.price *
            (adicionales.quantity - adicionales.initialQuantity);
        }
      });
      item.obligatorios.forEach((grupoObligatorios) => {
        grupoObligatorios.options.forEach((opcion) => {
          if (opcion.selected) {
            precioTotal += opcion.price;
          }
        });
      });

      return precioTotal * item.quantity;
    
  };

  const buy = (efectivo, total) => {
    if (efectivo === 0 || efectivo === "") efectivo = total
    if (efectivo >= total) { 
      setEfectivo(efectivo)
      localStorage.removeItem("orderItems");
      handleClick()
    } else {
      alert("Pago insuficiente...");
    }
  };
  
  const seeTicket = () => {
    let itemsTicket = itemsTickets(orderItems);

    navigate("/ticket", {
        state: { itemsTicket: itemsTicket, efectivo: efectivo, precioTotal: precioTotal },
      });
  };


  const itemsTickets = (orderItems) => {
    const items = orderItems.map((item) => ({
      title: addTamañoTitle(item),
      title_item: item.title,
      category: item.category,
      obligatorios: obtenerObligatorios(item),
      // modifiers: item.modifiers, //le pasamos todos los modifiers (ingredientes)
      // extras: item.extras,
      modifiers: addEditedModifiersAndExtras(item), //le pasamos solamente los modifiers y extras que se editaron (si se saco o agrego)
      adicionales: adicionalSelected(item),
      quantity: item.quantity,
      price: item.price === 0 ? getPrecioTamanio(item) : item.price
    }));

    return items;
  };

  const obtenerObligatorios = (item) => {
    const newItem = { ...item };

    // Filtrar los campos en obligatorios donde selected es true
    return newItem.obligatorios = newItem.obligatorios.map((campo) => ({
      ...campo,
      options: campo.options.filter((opcion) => opcion.selected === true),
    }));

  }


  const addTamañoTitle = (item) => {
    let title;
    let hasTamanio =
      item.obligatorios &&
      item.obligatorios.some((option) => option.title === "Tamaño");

    if (hasTamanio) {
      title = `${item.title} - ${
        item.obligatorios
          .find((option) => option.title === "Tamaño")
          .options.find((option) => option.selected).title
      }`;
    
    } else title = item.title;
    
    return title
  };

  const adicionalSelected = (item) => {
    const {adicionales} = item
    const adicionalesSelected = [...adicionales].filter((item) => item.quantity === 1)
    return adicionalesSelected
  }

  const addEditedModifiersAndExtras = (itemOriginal) => {
    const { modifiers, extras } = itemOriginal;
  
    const modifiedModifiersAndExtras = [
      ...modifiers,
      ...extras,
    ]
      .filter((item) => item.quantity !== item.initialQuantity)
      .map((item) => {
        const { title, price, initialQuantity, quantity, checkbox } = item;
        const newPrice = Math.max(0, price * (quantity - initialQuantity));
        const newQuantity = quantity - initialQuantity;
        return { title, total_price: newPrice, unitPrice: price, quantity: newQuantity, checkbox };
      });
  
    console.log(modifiedModifiersAndExtras);
    return modifiedModifiersAndExtras;
  };
  
  const getPrecioTamanio = (item) => {
    return  item.obligatorios.find((campo) => campo.title === "Tamaño").options.find((campo) => campo.selected === true).price;
  }
  

  return (
    <div style={{height:'100vh'}}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "10px",
          backgroundColor: "#eeeeee",
          
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ marginLeft: "5%" }}>
          Volver a Menu
        </Typography>
      </Container>

      <Container sx={{ display: "flex", flexDirection: "column" }}>
        {/* Botón para mostrar/ocultar el carrito */}
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setIsCartOpen(!isCartOpen)}
          sx={{ width: "100%", alignSelf: "center", marginTop: "10%" }}
        >
          {isCartOpen ? "Ocultar detalles de compra" : "Ver detalles de compra"}
          <ShoppingCartIcon sx={{ marginLeft: "5%" }} />
        </Button>
        {/* Lista del carrito */}
        <Collapse in={isCartOpen}>
          <List>
            {orderItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    backgroundColor: "aliceblue",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant="h6">
                    {addTamañoTitle(item)}
                  </Typography>
                  <Typography variant="body3">
                    -Cantidad: {item.quantity}
                  </Typography>
                  <Typography variant="body3">
                    -Total: ${priceCalculator(item)}
                  </Typography>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </Collapse>
        <Typography
          variant="h6"
          sx={{ marginTop: 2, textAlign: "center", color: "#00695c" }}
        >
          Total: ${precioTotal}
        </Typography>
        <Divider />
        <Box sx={{ marginTop: 5, textAlign: "center" }}>
          <TextField
            id="inputEfectivo"
            label="Ingrese efectivo"
            variant="outlined"
            value={efectivo}
            type="number"
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Box>
        <div
          style={{ display: "flex", justifyContent: "center", padding: "3%", marginTop:'5%' }}
        >
           <Button
          sx={{ marginLeft: "2rem" }}
          variant="outlined"
          color="success"
          onClick={() => navigate("/menu")}
        >
          Volver al inicio
        </Button>
          <Button
           sx={{ marginLeft: "2rem", backgroundColor: "rgb(255, 188, 13)" }}
           variant="contained"
           color="warning"
          onClick={() => buy(efectivo, precioTotal)}
        >
          Realizar Compra
        </Button>
       
        </div>
        
      </Container>
      {finishOrder && <FinishOrder seeTicket={seeTicket}/>}
    </div>
  );
};

export default Compra;
