export const allSubItems = [
    {name: 'Bienvenida', icon: 'https://cdn2.iconfinder.com/data/icons/flat-icons-19/512/Burger.png'},
    {name: 'Recompensas', icon: 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678132-gift-256.png'},
    {name: 'Ofertas y Promociones', icon: 'https://cdn4.iconfinder.com/data/icons/discount-19/64/speaker-discount-advertisement-promotion-megaphone-loudspeaker-marketing-256.png'},
]

export const allCategories = [
    {name: 'Hamburguesas', icon: 'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1006_v2.png'},
    {name: 'Bebidas', icon: 'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_430_v3.png'},
    {name: 'Acompañamientos', icon: 'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-GRANDE.png'},
    {name: 'Cafes', icon: 'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_2019_v1.png'},
    {name: 'Postres', icon: 'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_820_v1.png'},
    {name: 'Combos', icon: 'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26785.png'},
]

export const allItems = [
    {title: 'Doble Bacon Cheddar McMelt', category: 'Hamburguesas', description: 'Deliciosa hamburguesa con dos medallones de carne, abundante queso cheddar, dos tiras de bacon y cebolla grillada.', quantity: 1, price: 4050.00, 
    modifiers:[
        {title: 'Pan', price: 0.00, initialQuantity:1, quantity: 1, max: 1, checkbox: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_PanXl_07-06-23.png'},
        {title: 'Carne', price: 1200.00, initialQuantity:2, quantity: 2, max: 3, checkbox: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_RegularMeatPatty.png'},
        {title: 'Queso Cheddar Fundido', price: 0.00, initialQuantity:1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/ngk_cheddar_melt.png'},
        {title: 'Cebolla Grillada', price: 120.00, initialQuantity:1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_onion.png'},
        {title: 'Bacon', price: 700.00, initialQuantity:1, quantity: 1, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bacon.png'},
    ],
    extras:[],
    adicionales:[],
    obligatorios:[],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kPXPtaX9/200/200/original?country=ar"},

   
    {title: 'Hamburguesa con Queso', category: 'Hamburguesas', description: 'Hamburguesa con queso Cheddar, cebolla, mostaza y ketchup.', quantity: 1, price: 2000.00, modifiers:[
        {title: 'Pan Regular', price: 0.00, initialQuantity: 1 ,quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1000001.png'},
        {title: 'Cebolla', price: 0.00, initialQuantity: 1 ,quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_onion.png'},
        {title: 'Mostaza', price: 0.00, initialQuantity: 1 ,quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_mustard.png'},
        {title: 'Queso Cheddar en fetas', price: 500.00, initialQuantity: 1 ,quantity: 1, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png'},
        {title: 'Carne', price: 900.00, initialQuantity: 1 ,quantity: 1, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_RegularMeatPatty.png'},
        {title: 'Ketchup', price: 0.00, initialQuantity: 1 ,quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ketchup.png'},
    ],
    extras:[
        {title: 'Tomate', price: 500.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_tomato.png'},
        {title: 'Bacon', price: 700.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bacon.png'},
    ],
    adicionales:[],
    obligatorios:[],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kqX8TYcp/200/200/original?country=ar"},

    {title: 'McPollo', category: 'Hamburguesas', description: 'Hamburguesa de pollo, con un colchón de lechuga y mayonesa.', quantity: 1, price: 2300.00, modifiers:[
        {title: 'Pan Cuarto', price: 0.00, initialQuantity: 1, quantity: 1,checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1009513.png'},
        {title: 'Lechuga', price: 0.00, initialQuantity: 1, quantity: 1,checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_shreddedlettuce.png'},
        {title: 'McPollo', price: 700.00, initialQuantity: 1, quantity: 1,checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_Ingredientes_McPollo.png'},
        {title: 'Mayonesa', price: 0.00, initialQuantity: 1, quantity: 1,checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_mayo.png'},
    ],
    extras:[
        {title: 'Queso Cheddar en fetas', price: 500.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png'},
        {title: 'Tomate', price: 500.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_tomato.png'},
        {title: 'Bacon', price: 700.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bacon.png'},
    ],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kcXQzmQj/200/200/original?country=ar"},

    {title: 'Grand Tasty Turbo Bacon Triple', category: 'Hamburguesas', description: 'Hamburguesa con pan mas grande, triple carne, bacon en tiras, salsa tasty, 4 fetas de cheddar, lechuga, tomate y cebolla', quantity: 1, price: 4500.00, 
    modifiers:[
        {title: 'Pan XL', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1009085.png"},
        {title: 'Carne', price: 1200.00, initialQuantity: 3, quantity: 3, checkbox: false, max: 3, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_RegularMeatPatty.png"},
        {title: 'Queso Cheddar en fetas', price: 500.00, initialQuantity: 4, quantity: 4, checkbox: false, max: 6, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png"},
        {title: 'Cebolla', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_sliveredonion.png"},
        {title: 'Tomate', price: 500.00, initialQuantity: 2, quantity: 2, checkbox: false, max: 4, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_tomato.png"},
        {title: 'Lechuga', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_shreddedlettuce.png"},
        {title: 'Salsa Tasty', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Sin%20Tasty.png"},
        {title: 'Bacon', price: 700.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 2, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bacon.png"},
        {title: 'Bacon triturado', price: 500.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 2, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Bacon%20triturado.png"},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$ksX5rs2H/200/200/original?country=ar"},

    {title: 'Triple Hamburguesa con Queso', category: 'Hamburguesas', description: 'Tres medallones de carne, el queso cheddar mas rico, cebolla triturada, mostaza y ketchup.', quantity: 1, price: 3150.00, modifiers:[
        {title: 'Pan Regular', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1000001.png'},
        {title: 'Cebolla', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_onion.png'},
        {title: 'Queso Cheddar en fetas', price: 500.00, initialQuantity: 2, quantity: 2, checkbox: false, max: 4, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png'},
        {title: 'Carne', price: 900.00, initialQuantity: 3, quantity: 3, checkbox: false, max: 5, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_RegularMeatPatty.png'},
        {title: 'Mostaza', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_mustard.png'},
        {title: 'Ketchup', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ketchup.png'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kqXsCqnz/200/200/original?country=ar"},

/*========================================================================================================================================================== */
    
    {category: 'Postres', title: 'Cono', description: 'Conito helado muy cremoso!', quantity: 1, price: 500.00, 
    modifiers:[],
    extras:[
        {title: 'Extra Salsa Frutilla', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Frutilla.png'},
        {title: 'Extra Salsa Dulce de Leche', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Ddl.png'},
        {title: 'Extra Salsa Chocolate', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Chocolate.png'},
    ],
    adicionales:[],
    obligatorios:[
        {title: "Sabor", options: 
            [
                {title:'Helado de Vainilla', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20Vainilla.png'},
                {title:'Helado de Combinado', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20combinado.png'},
                {title:'Helado de Dulce de Leche', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20DDL.png'},
            ],
        },
    ],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$cono-de-vainilla.png/200/200/original?country=ar"},
    
    {category: 'Postres', title: 'Sundae', description: 'Pote de helado con una deliciosa salsa tibia a elección.', quantity: 1, price: 1350.00, 
    modifiers:[],
    extras:[
        {title: 'Extra Salsa Frutilla', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Frutilla.png'},
        {title: 'Extra Salsa Dulce de Leche', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Ddl.png'},
        {title: 'Extra Salsa Chocolate', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Chocolate.png'},
    ],
    adicionales:[],
    obligatorios:[
        {title: "Sabor", options: 
            [
                {title:'Helado de Vainilla', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20Vainilla.png'},
                {title:'Helado de Combinado', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20combinado.png'},
                {title:'Helado de Dulce de Leche', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20DDL.png'},
            ],
        },
        {title: "Salsa", options: 
            [
                {title: 'Salsa Chocolate', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Chocolate.png'},
                {title: 'Salsa Frutilla', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Frutilla.png'},
                {title: 'Salsa Dulce de Leche', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Ddl.png'},
                {title: 'Sin cubierta', price: 0.00, selected: false, icon:'https://www.mcdonalds.com.ar/images/icons/option-placeholder.svg'},
            ],
        },
    ],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$sundae-chocolate.png/200/200/original?country=ar"},

    {category: 'Postres', title: 'McFlurry Oreo', description: 'Helado con salsa y un top con tus galletitas preferidas.', quantity: 1, price: 1850.00,
    modifiers:[
        {title: 'Oreo', price: 350.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Extra%20oreo.png'},
    ],
    extras:[
        {title: 'Extra Salsa Frutilla', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Frutilla.png'},
        {title: 'Extra Salsa Dulce de Leche', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Ddl.png'},
        {title: 'Extra Salsa Chocolate', price: 250.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Chocolate.png'},
        {title: 'KitKat', price: 350.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Chocolate%20KitKat.png'},
    ],
    adicionales:[],
    obligatorios:[
        {title: "Sabor", options: 
            [
                {title:'Helado de Vainilla', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20Vainilla.png'},
                {title:'Helado de Combinado', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20combinado.png'},
                {title:'Helado de Dulce de Leche', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Mix%20DDL.png'},
            ],
        },
        {title: "Salsa", options: 
            [
                {title: 'Salsa Chocolate', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Chocolate.png'},
                {title: 'Salsa Frutilla', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Frutilla.png'},
                {title: 'Salsa Dulce de Leche', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK_Topping-Ddl.png'},
                {title: 'Sin cubierta', price: 0.00, selected: false, icon:'https://www.mcdonalds.com.ar/images/icons/option-placeholder.svg'},
            ],
        },
    ],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kcX83hlT/200/200/original?country=ar"},


/*========================================================================================================================================================== */

    {title: 'Coca', category: 'Bebidas', description: 'Refrescante gaseosa Coca Cola.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
    ],
    extras:[],
    adicionales:[],
    obligatorios:[
        {title: "Tamaño", options: 
            [
                {title:'Chica', price: 800.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_430_v3.png'},
                {title:'Mediana', price: 900.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_500_v3.png'},
                {title:'Grande', price: 1000.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_570_v3.png'},
                {title:'Extra-Grande', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_570_v3.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_500_v3.png"},
    
    {title: 'Sprite Zero', category: 'Bebidas', description: 'Refrescante gaseosa Sprite Zero.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
    ],
    extras:[],
    adicionales:[],
    obligatorios:[
        {title: "Tamaño", options: 
            [
                {title:'Chica', price: 800.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_7085_v2.png'},
                {title:'Mediana', price: 900.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_7086_v2.png'},
                {title:'Grande', price: 1000.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_7087_v2.png'},
                {title:'Extra-Grande', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_7087_v2.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_7086_v2.png"},
    
    {title: 'Fanta Zero', category: 'Bebidas', description: 'Refrescante gaseosa Fanta Zero.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
    ],
    extras:[],
    adicionales:[],
    obligatorios:[
        {title: "Tamaño", options: 
            [
                {title:'Chica', price: 800.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_460_v2.png'},
                {title:'Mediana', price: 900.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_530_v2.png'},
                {title:'Grande', price: 1000.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_600_v2.png'},
                {title:'Extra-Grande', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_600_v2.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_530_v2.png"},
    
    {title: 'Cafe Americano', category: 'Bebidas', description: 'Cálido Cafe Americano.', quantity: 1, price: 0.00, 
    modifiers:[],
    extras:[],
    adicionales:[
        {title: 'Azucar', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTwDr-fEqvCnOrsO86p8joE-S265RP-B0ETf3MbgddRTamCuHtAiI6v1SPXSlNBxgl3fs&usqp=CAU'},
        {title: 'Edulcorante', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTwDr-fEqvCnOrsO86p8joE-S265RP-B0ETf3MbgddRTamCuHtAiI6v1SPXSlNBxgl3fs&usqp=CAU'},
    ],
    obligatorios:[
        {title: "Tamaño", options: 
            [
                {title:'Chica', price: 850.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_768_v1.png'},
                {title:'Mediana', price: 950.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_768_v1.png'},
                {title:'Grande', price: 1050.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_768_v1.png'},
                {title:'Extra-Grande', price: 1150.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_768_v1.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_768_v1.png"},


/*==========================================================================================================================================================*/

    {title: 'Vainilla Latte', category: 'Cafes', description: 'Delicioso Latte sabor Vainilla.', quantity: 1, price: 0.00, 
    modifiers:[],
    extras:[
        {title: 'Extra: Crema Doble', price: 450.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    adicionales:[],
    obligatorios:[
        {title: "Tamaño", options: 
            [
                {title:'Chica', price: 1150.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_2869_1.png'},
                {title:'Grande', price: 1300.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_2869_1.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_2869_1.png"},

    {title: 'Submarino', category: 'Cafes', description: 'Leche caliente con una barra de chocolate para taza.', quantity: 1, price: 0.00, 
    modifiers:[],
    extras:[
        {title: 'Extra: Crema Doble', price: 450.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    adicionales:[],
    obligatorios:[
        {title: "Tamaño", options: 
            [
                {title:'Chica', price: 1100.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_2878.png'},
                {title:'Grande', price: 1200.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_2878.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_2878.png"},

    {title: 'Cappuccino Mocca', category: 'Cafes', description: 'Una exquisita combinación de café espresso, leche y salsa tibia de chocolate. Finalizado con espuma de leche y cacao en polvo.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Chocolate', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:''},
    ],
    extras:[
        {title: 'Extra: Crema Doble', price: 450.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    adicionales:[
        {title: 'Azucar', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTwDr-fEqvCnOrsO86p8joE-S265RP-B0ETf3MbgddRTamCuHtAiI6v1SPXSlNBxgl3fs&usqp=CAU'},
        {title: 'Edulcorante', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 2, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTwDr-fEqvCnOrsO86p8joE-S265RP-B0ETf3MbgddRTamCuHtAiI6v1SPXSlNBxgl3fs&usqp=CAU'},
    ],
    obligatorios:[
        {title: "Tamaño", options: 
            [
                {title:'Chica', price: 1150.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_2840_v1.png'},
                {title:'Grande', price: 1300.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Mc%20Cafe%202.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Mc%20Cafe%202.png"},

    {title: 'MilkShake', category: 'Cafes', description: 'Bebida frozen con una base de vainilla con salsa de dulce de leche y finalizado con deliciosa crema de leche.', quantity: 1, price: 1600.00, 
    modifiers:[
        {title: 'Crema', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:''},
    ],
    extras:[
        {title: 'Extra: Crema Doble', price: 450.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    adicionales:[],
    obligatorios:[
        {title: "Sabor", options: 
            [
                {title:'Vainilla', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-milkshake%20vainilla.png'},
                {title:'Chocolate', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-milkshake%20chocolate.png'},
                {title:'Dulce de Leche', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20milkshake%20dll.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-milkshake%20vainilla.png"},

    {title: 'MilkShake Oreo', category: 'Cafes', description: 'Bebida frozen con una base de vainilla con salsa de dulce de leche y finalizado con deliciosa crema de leche y un topping con lluvia de galletitas Oreo.', quantity: 1, price: 1700.00, 
    modifiers:[
        {title: 'Crema', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:''},
    ],
    extras:[
        {title: 'Extra: Crema Doble', price: 450.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    adicionales:[],
    obligatorios:[
        {title: "Sabor", options: 
            [
                {title:'Frutilla', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Nuevo%20milkshake%20de%20frutilla%20con%20oreo.png'},
                {title:'Vainilla', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20milkshake%20vainilla%20con%20oreo.png'},
                {title:'Dulce de Leche', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20milkshake%20DDL%20con%20oreo.png'},
                {title:'Chocolate', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20milkshake%20chocolate%20con%20oreo%20ESP.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_8318_v1.png"},
    
/*==========================================================================================================================================================*/


    {title: 'Papas Fritas', category: 'Acompañamientos', description: 'Calientes, crujientes y deliciosas. Disfrutá de nuestras papas mundialmente famosas, desde la primera hasta la última.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[
        {title: "Tamaño", options: 
        [
            {title:'Chica', price: 1050.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Papas-Chicas.png'},
            {title:'Mediana', price: 1150.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-MEDIANA.png'},
            {title:'Grande', price: 1200.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-GRANDE.png'},
        ],
    },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-GRANDE.png"},

    {title: 'McNuggets', category: 'Acompañamientos', description: 'Calientes, crujientes y deliciosas. Disfrutá de nuestros Nuggets mundialmente famosas, desde la primera hasta la última.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[
        {title: "Tamaño", options: 
        [
            {title:'x10', price: 1050.00, selected: true, icon:'https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kcX2hh1W/200/200/original?country=ar'},
            {title:'x15', price: 1150.00, selected: false, icon:'https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kcX2hh1W/200/200/original?country=ar'},
            {title:'x20', price: 1200.00, selected: false, icon:'https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kcX2hh1W/200/200/original?country=ar'},
        ],
    },
    ],
    icon:"https://cache-backend-mcd.mcdonaldscupones.com/media/image/product$kcX2hh1W/200/200/original?country=ar"},

    {title: 'Papas Cheddar', category: 'Acompañamientos', description: 'Papas fritas queso cheddaar fundido. Disfrutá de nuestras papas mundialmente famosas, desde la primera hasta la última.', quantity: 1, price: 1350.00, 
    modifiers:[
        {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
        {title: 'Queso Cheddar Fundido', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/ngk_cheddar_melt.png'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/McCheddarBaconCajita.png"},

    {title: 'Papas con Cheddar y Bacon', category: 'Acompañamientos', description: 'Papas fritas queso cheddaar fundido y bacon. Disfrutá de nuestras papas mundialmente famosas, desde la primera hasta la última.', quantity: 1, price: 1550.00, 
    modifiers:[
        {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
        {title: 'Queso Cheddar Fundido', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/ngk_cheddar_melt.png'},
        {title: 'Bacon Triturado', price: 400.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Bacon%20triturado.png'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-%205761%20PapasCheddarBacon.png"},

    {title: 'Papas Tasty', category: 'Acompañamientos', description: 'Papas con salsa Tasty.', quantity: 1, price: 1350.00, 
    modifiers:[
        {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-Papas%20Tasty%20Bacon.png"},

    {title: 'Papas Tasty y Bacon', category: 'Acompañamientos', description: 'Papas con salsa Tasty.', quantity: 1, price: 1550.00, 
    modifiers:[
        {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Papas%20Tasty%20Bacon.png"},


    /*==========================================================================================================================================================*/

    {title: 'Bacon Cheddar McMelt', category: 'Combos', description: 'Una indulgente propuesta que potencia el sabor de nuestra clásica hamburguesa con abundante queso cheddar, dos tiras de bacon enteras, cebolla grillada en nuestro inigualable pan con semillas de sésamo! Acompañamiento y bebida a elección.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Pan', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_PanXl_07-06-23.png'},
        {title: 'Queso Cheddar en Fetas', price: 400.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png'},
        {title: 'Cebolla Grillada', price: 120.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_onion.png'},
        {title: 'Bacon', price: 120.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bacon.png'},
        {title: 'Queso Fundido Cheddar', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/ngk_cheddar_melt.png'},
        {title: 'Carne', price: 1100.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_RegularMeatPatty.png'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[
        {title: "Tamaño", options:
            [
                {title:"Chico", price: 4100.00, selected: true, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_48231_v2.png"},
                {title:"Mediano", price: 4300.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_48231_v2.png"},
                {title:"Grande", price: 4500.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_48232_v2.png"},
            ]
        },
        {title: "Acompañamiento", options: 
            [
                {title:'Papas Fritas', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-MEDIANA.png'},
                {title:'Papas Tasty y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Papas%20Tasty%20Bacon.png'},
                {title:'Papas Tasty', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-Papas%20Tasty%20Bacon.png'},
                {title:'Papas con Cheddar', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/McCheddarBaconCajita.png'},
                {title:'Papas con Cheddar y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-%205761%20PapasCheddarBacon.png'},
                {title:'Ensalada', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1304_v1.png'},
            ],
            modifiers:[
                {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
            ],
            
        },
        {title: "Bebida", options: 
            [
                {title:'Coca-Cola Zero', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42007_v1.png'},
                {title:'Coca-Cola', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42005_v1.png'},
                {title:'Sprite Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42008_v1.png'},
                {title:'Fanta Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42009_v1.png'},
                {title:'Agua', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42027_v1.png'},
                {title:'Jugo de Naranja', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Jugo%20Naranja.png'},
            ],
            modifiers:[
                {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_48231_v2.png"},

    {title: 'Big Mac', category: 'Combos', description: 'Hamburguesa con doble carne 100% vacuna, salsa Big Mac, queso cheddar, cebolla, lechuga y pepino. Acompañamiento y bebida grande a elección.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Pan Big Mac', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1000003.png'},
        {title: 'Cebolla', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_sliveredonion.png'},
        {title: 'Salsa Big Mac', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bigmacsauce.png'},
        {title: 'Lechuga', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_shreddedlettuce.png'},
        {title: 'Queso Cheddar en Fetas', price: 400.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png'},
        {title: 'Carne', price: 850.00, initialQuantity: 2, quantity: 2, checkbox: false, max: 4, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_RegularMeatPatty.png'},
        {title: 'Pepino', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cucumber.png'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[
        {title: "Tamaño", options:
            [
                {title:"Chico", price: 3300.00, selected: true, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26000_v2.png"},
                {title:"Mediano", price: 3700.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26000_v2.png"},
                {title:"Grande", price: 4150.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26050_v2.png"},
            ]
        },
        {title: "Acompañamiento", options: 
            [
                {title:'Papas Fritas', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-MEDIANA.png'},
                {title:'Papas Tasty y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Papas%20Tasty%20Bacon.png'},
                {title:'Papas Tasty', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-Papas%20Tasty%20Bacon.png'},
                {title:'Papas con Cheddar', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/McCheddarBaconCajita.png'},
                {title:'Papas con Cheddar y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-%205761%20PapasCheddarBacon.png'},
                {title:'Ensalada', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1304_v1.png'},
            ],
            modifiers:[
                {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
            ],
            
        },
        {title: "Bebida", options: 
            [
                {title:'Coca-Cola Zero', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42007_v1.png'},
                {title:'Coca-Cola', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42005_v1.png'},
                {title:'Sprite Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42008_v1.png'},
                {title:'Fanta Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42009_v1.png'},
                {title:'Agua', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42027_v1.png'},
                {title:'Jugo de Naranja', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Jugo%20Naranja.png'},
            ],
            modifiers:[
                {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26050_v2.png"},

    {title: 'Grand Doble Tasty', category: 'Combos', description: 'Hamburguesa con doble carne 100% vacuna, salsa tasty, 3 fetas de queso cheddar, lechuga, tomate, cebolla en un pan mas grande. Acompañamiento y bebida grande a elección.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Pan XL', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1009085.png'},
        {title: 'Cebolla', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_sliveredonion.png'},
        {title: 'Salsa Tasty', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Sin%20Tasty.png'},
        {title: 'Lechuga', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_shreddedlettuce.png'},
        {title: 'Queso Cheddar en Fetas', price: 400.00, initialQuantity: 3, quantity: 3, checkbox: false, max: 5, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png'},
        {title: 'Carne', price: 1100.00, initialQuantity: 2, quantity: 2, checkbox: false, max: 4, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_RegularMeatPatty.png'},
        {title: 'Tomate', price: 400.00, initialQuantity: 2, quantity: 2, checkbox: false, max: 4, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_tomato.png'},
    ],
    extras:[
        {title: 'Extra: Bacon', price: 650.00, initialQuantity: 0, quantity: 0, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bacon.png'},
    ],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[
        {title: "Tamaño", options:
            [
                {title:"Chico", price: 4000.00, selected: true, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26933_v2.png"},
                {title:"Mediano", price: 4500.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26933_v2.png"},
                {title:"Grande", price: 5000.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26937_v2.png"},
            ]
        },
        {title: "Acompañamiento", options: 
            [
                {title:'Papas Fritas', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-MEDIANA.png'},
                {title:'Papas Tasty y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Papas%20Tasty%20Bacon.png'},
                {title:'Papas Tasty', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-Papas%20Tasty%20Bacon.png'},
                {title:'Papas con Cheddar', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/McCheddarBaconCajita.png'},
                {title:'Papas con Cheddar y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-%205761%20PapasCheddarBacon.png'},
                {title:'Ensalada', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1304_v1.png'},
            ],
            modifiers:[
                {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
            ],
            
        },
        {title: "Bebida", options: 
            [
                {title:'Coca-Cola Zero', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42007_v1.png'},
                {title:'Coca-Cola', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42005_v1.png'},
                {title:'Sprite Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42008_v1.png'},
                {title:'Fanta Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42009_v1.png'},
                {title:'Agua', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42027_v1.png'},
                {title:'Jugo de Naranja', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Jugo%20Naranja.png'},
            ],
            modifiers:[
                {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26937_v2.png"},

    {title: 'Grand Tasty Turbo Bacon Triple', category: 'Combos', description: 'Hamburguesa con triple carne 100% vacuna, bacon en tiras y triturado, salsa tasty, 4 fetas de queso cheddar, lechuga, tomate y cebolla en un pan mas grande. Acompañamiento y bebida grande a elección.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Pan XL', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1009085.png'},
        {title: 'Cebolla', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_sliveredonion.png'},
        {title: 'Salsa Tasty', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Sin%20Tasty.png'},
        {title: 'Lechuga', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_shreddedlettuce.png'},
        {title: 'Queso Cheddar en Fetas', price: 400.00, initialQuantity: 4, quantity: 4, checkbox: false, max: 6, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png'},
        {title: 'Carne', price: 1100.00, initialQuantity: 3, quantity: 3, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_RegularMeatPatty.png'},
        {title: 'Tomate', price: 400.00, initialQuantity: 2, quantity: 2, checkbox: false, max: 4, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_tomato.png'},
        {title: 'Bacon', price: 650.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bacon.png'},
        {title: 'Bacon Triturado', price: 400.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20Bacon%20triturado.png'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[
        {title: "Tamaño", options:
            [
                {title:"Chico", price: 5000.00, selected: true, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26957_v2.png"},
                {title:"Mediano", price: 5550.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26957_v2.png"},
                {title:"Grande", price: 6100.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26959_v2.png"},
            ]
        },
        {title: "Acompañamiento", options: 
            [
                {title:'Papas Fritas', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-MEDIANA.png'},
                {title:'Papas Tasty y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Papas%20Tasty%20Bacon.png'},
                {title:'Papas Tasty', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-Papas%20Tasty%20Bacon.png'},
                {title:'Papas con Cheddar', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/McCheddarBaconCajita.png'},
                {title:'Papas con Cheddar y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-%205761%20PapasCheddarBacon.png'},
                {title:'Ensalada', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1304_v1.png'},
            ],
            modifiers:[
                {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
            ],
            
        },
        {title: "Bebida", options: 
            [
                {title:'Coca-Cola Zero', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42007_v1.png'},
                {title:'Coca-Cola', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42005_v1.png'},
                {title:'Sprite Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42008_v1.png'},
                {title:'Fanta Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42009_v1.png'},
                {title:'Agua', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42027_v1.png'},
                {title:'Jugo de Naranja', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Jugo%20Naranja.png'},
            ],
            modifiers:[
                {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26959_v2.png"},

    {title: 'McCrispy Chicken Cheddar y Bacon', category: 'Combos', description: 'Medallón de pollo Crispy, cheddar, bacon, lechuga, tomate y mayonesa. Acompañamiento y bebida grande a elección.', quantity: 1, price: 0.00, 
    modifiers:[
        {title: 'Pan de Papa', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1006070.png'},
        {title: 'Queso Cheddar en Fetas', price: 400.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 3, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_cheese.png'},
        {title: 'Pollo Crispy', price: 650.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Pollo%20Crispy%20.png'},
        {title: 'Tomate', price: 400.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_tomato.png'},
        {title: 'Bacon', price: 650.00, initialQuantity: 1, quantity: 1, checkbox: false, max: 2, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_bacon.png'},
        {title: 'Lechuga', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_shreddedlettuce.png'},
        {title: 'Mayonesa', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_mayo.png'},
    ],
    extras:[],
    adicionales:[
        {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
        {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    ],
    obligatorios:[
        {title: "Tamaño", options:
            [
                {title:"Chico", price: 5000.00, selected: true, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_48094_v2.png"},
                {title:"Mediano", price: 5550.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_48094_v2.png"},
                {title:"Grande", price: 6100.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_48102_v2.png"},
            ]
        },
        {title: "Acompañamiento", options: 
            [
                {title:'Papas Fritas', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-MEDIANA.png'},
                {title:'Papas Tasty y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Papas%20Tasty%20Bacon.png'},
                {title:'Papas Tasty', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-Papas%20Tasty%20Bacon.png'},
                {title:'Papas con Cheddar', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/McCheddarBaconCajita.png'},
                {title:'Papas con Cheddar y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-%205761%20PapasCheddarBacon.png'},
                {title:'Ensalada', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1304_v1.png'},
            ],
            modifiers:[
                {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
            ],
            
        },
        {title: "Bebida", options: 
            [
                {title:'Coca-Cola Zero', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42007_v1.png'},
                {title:'Coca-Cola', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42005_v1.png'},
                {title:'Sprite Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42008_v1.png'},
                {title:'Fanta Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42009_v1.png'},
                {title:'Agua', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42027_v1.png'},
                {title:'Jugo de Naranja', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Jugo%20Naranja.png'},
            ],
            modifiers:[
                {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
            ],
        },
    ],
    icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_48102_v2.png"},
    

    // {title: 'McNuggets', category: 'Combos', description: 'McNuggets de pollo. Acompañamiento y bebida grande a elección.', quantity: 1, price: 0.00, 
    // modifiers:[],
    // extras:[],
    // adicionales:[
    //     {title: 'Sobre de Ketchup', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    //     {title: 'Sobre de Mostaza', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    //     {title: 'Sobre de Mayonsea', price: 0.00, initialQuantity: 0, quantity: 0, checkbox: true, max: 1, icon:''},
    // ],
    // obligatorios:[
    //     {title: "Tamaño", options:
    //         [
    //             {title:"x10", price: 4850.00, selected: true, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26055_v1.png"},
    //             {title:"x20", price: 5350.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26356_v1.png"},
    //         ]
    //     },
    //     {title: "Acompañamiento", options: 
    //         [
    //             {title:'Papas Fritas', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/PAPA-MEDIANA.png'},
    //             {title:'Papas Tasty y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/Papas%20Tasty%20Bacon.png'},
    //             {title:'Papas Tasty', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-Papas%20Tasty%20Bacon.png'},
    //             {title:'Papas con Cheddar', price: 850.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/McCheddarBaconCajita.png'},
    //             {title:'Papas con Cheddar y Bacon', price: 1100.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK%20-%205761%20PapasCheddarBacon.png'},
    //             {title:'Ensalada', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_1304_v1.png'},
    //         ],
    //         modifiers:[
    //             {title: 'Sal', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16tpvcpUVtjQ1z5BnbEfNqAWkcgV3aWgaxg&usqp=CAU'},
    //         ],
            
    //     },
    //     {title: "Bebida", options: 
    //         [
    //             {title:'Coca-Cola Zero', price: 0.00, selected: true, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42007_v1.png'},
    //             {title:'Coca-Cola', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42005_v1.png'},
    //             {title:'Sprite Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42008_v1.png'},
    //             {title:'Fanta Zero', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42009_v1.png'},
    //             {title:'Agua', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42027_v1.png'},
    //             {title:'Jugo de Naranja', price: 0.00, selected: false, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/NGK-%20Jugo%20Naranja.png'},
    //         ],
    //         modifiers:[
    //             {title: 'Hielo', price: 0.00, initialQuantity: 1, quantity: 1, checkbox: true, max: 1, icon:'https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/gui_uk_ngk_grill_ice.png'},
    //         ],
    //     },
    //      {title: "Salsa Nuggets", options:
    //         [
    //             {title:"Salsa Barbacoa", price: 0.00, selected: true, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42167.png"},
    //             {title:"Salsa Honey", price: 0.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42168.png"},
    //             {title:"Salsa Spicy", price: 0.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42169.png"},
    //             {title:"Salsa Creamy", price: 0.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42178V1.png"},
    //         ]
    //     },
    //     {title: "Elección Salsa", options:
    //         [
    //             {title:"Salsa Honey", price: 0.00, selected: true, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42168.png"},
    //             {title:"Salsa Spicy", price: 0.00, selected: false, icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_42169.png"},
    //         ]
    //     },
    // ],
    // icon:"https://cache-mcd-ecommerce.appmcdonalds.com/images/AR/DLV_26356_v1.png"},

];

