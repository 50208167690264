import React from "react";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  useMediaQuery
} from "@mui/material";


const LeftComponent = ({ items, selectedItem, onSelectedItem, title }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  }
  const handleSelectedCategory = (category) => {
    onSelectedItem(category);
    handleScrollToTop()
    sessionStorage.setItem("selectedCategory", category);
  };
  const handleSelectedSubItem = (subItem) => {
    onSelectedItem(subItem);
    sessionStorage.setItem("selectedSubItem", subItem);
  };

  return (
    <Paper
      elevation={ isMobile ? 0 : 3}
      sx={{ 
      borderRadius:  isMobile ? 0 : '3%', 
      height: "100%", 
      overflow: "auto" 
    }}
    >
      <Typography
        variant="h6"
        sx={{ textAlign: "center", backdropFilter: "blur(0)" }}
      >
        {title}
      </Typography>
      <div sx={{ width: "100%", height: "85%", overflow: "auto" }}>
        <List sx={{ backdropFilter: "blur(0)" }}>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                selected={item.name === selectedItem}
                onClick={
                  title === "Menu"
                    ? () => {
                        handleSelectedCategory(item.name);
                      }
                    : () => {
                      handleSelectedSubItem(item.name);
                    } 
                }
                sx={{ cursor: "pointer", paddingY: 1, paddingRight: 3, paddingLeft: 1 }}
              >
                <ListItemAvatar>
                  <Avatar alt={item.name} src={item.icon} />
                </ListItemAvatar>
                <ListItemText primary={item.name} />
              </ListItem>
              {index < items.length - 1 && <Divider />}{" "}
            </React.Fragment>
          ))}
        </List>
      </div>
    </Paper>
  );
};

export default LeftComponent;
//x
